import React, { useMemo } from 'react'
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { Button, Eye, IconButton, Pencil, Refresh } from 'nzk-react-components';
import GET_ASSIGNMENTS_LINEUPS from '../data/AssignmentsLineup/graphql/getAssignmentsLineups.graphql'
import Table from '../../../TableV2';
import useAsync from '../../../../hooks/useAsync';
import { useQuery } from '@apollo/client';

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const AssignmentsLineupsPage = () => {
  const query = new URLSearchParams(location.search)

  const { data, loading, refetch } = useQuery(GET_ASSIGNMENTS_LINEUPS, {
    fetchPolicy: query.get('refetch') === 'true' ? 'network-only' : undefined
  })
  const assignmentsLineups = data?.starTutoring_assignmentsLineups

  const formattedLineups = useMemo(() => {
    return [...(assignmentsLineups || [])].sort((a, b) => {
      if (a.title > b.title) return -1
      if (a.title === b.title) return 0
      return 1
    })
  }, [assignmentsLineups])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Title',
        'accessor': 'title',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Link to={`/star-tutoring/lineups/${row.original._id}`}>{row.original.default ? '✅' : ''} {row.original.title}</Link>
      },
      {
        'Header': 'Year Group',
        'accessor': 'yearGroup',
        disableFilters: true,
      },
      {
        'Header': 'Ed. Category',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => {
          return row.original.educationCategory
            ? <Link to={`/star-tutoring/education-categories/${row.original.educationCategory._id}/edit`}>{row.original.educationCategory.title}</Link>
            : <div>Not set</div>
        }
      },
      {
        'Header': 'ID',
        'accessor': '_id',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Link to={`/star-tutoring/lineups/${row.original._id}`}>{row.original._id}</Link>
      },
      {
        Header: 'Actions',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
          <Link to={`/star-tutoring/lineups/${row.original._id}`}>
            <IconButton icon={<Eye />} size='x-small' theme='primary'>More</IconButton>
          </Link>
          <Link to={`/star-tutoring/lineups/${row.original._id}/edit`}>
            <IconButton icon={<Pencil />} size='x-small' theme='orange'>Edit</IconButton>
          </Link>
        </Actions>
      }
    ]
  }, [formattedLineups])

  return <Wrapper>
    <Header>
      <IconButton
        size='x-small'
        theme='primary'
        icon={<Refresh />}
        onClick={() => refetch()}>Refresh</IconButton>
      <Button size='x-small' theme='confirm' onClick={() => navigate('/star-tutoring/lineups/create')}>Create</Button>
    </Header>
    <Table columns={columns} data={formattedLineups} loading={loading} />
  </Wrapper>
}

export default AssignmentsLineupsPage
