import React from 'react'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'
import AssignmentsLineupsPage from '../../../components/pages/StarTutoring/LineupsPage'

const Page = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <AssignmentsLineupsPage />
  </StarTutoringLayout>
}

export default Page
